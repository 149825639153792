import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section";
import {
  HeroBanner,
  HeroTitle,
  HeroCaption,
  HeroContent,
  HeroLeft,
  HeroRight,
  HeroCard,
} from "../components/HeroBanner";
import { CheckIcon } from "../components/Icons";
import JoinForm from "../components/JoinForm";

const GridRow = styled.div`
  position: relative;
`;
const Grid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  @media (min-width: 992px) {
    margin: 0 -30px;
  }
`;
const GridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
  @media (min-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 30px;
    margin-bottom: 40px;
  }
  @media (min-width: 1200px) {
    margin-bottom: 80px;
  }
`;
const MediaCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 80px;
  width: 100%;
  margin-bottom: 15px;
  @media (min-width: 576px) {
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    max-width: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  span {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border: 3px solid #ffe100;
    @media (min-width: 992px) {
      width: 50px;
      height: 50px;
      border: 5px solid #ffe100;
    }
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.15);
    > svg {
      fill: #002765;
      @media (max-width: 991.99px) {
        width: 18px;
      }
    }
  }
  strong {
    font-weight: 700;
    color: #161420;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    line-height: 60px;
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
    @media (min-width: 768px) {
      font-size: 60px;
      line-height: 80px;
    }
  }
`;
const MediaThumbnail = styled.div`
  position: relative;
  @media (min-width: 768px) {
    padding-left: 120px;
  }
`;
const MediaBody = styled.div`
  position: relative;
  padding: 10px 10px 20px;
  border-radius: 15px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
`;
const MediaFigure = styled.div`
  .gatsby-image-wrapper {
    img {
      border-radius: 15px;
    }
  }
`;
const MediaCaption = styled.div`
  position: relative;
  text-align: center;
  padding: 15px;
  margin: -40px 10px 0;
  @media (min-width: 576px) {
    padding: 20px 15px;
    margin: -40px 15px 0;
  }

  &:before {
    content: "";
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid #f5f5f5;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
  }
`;
const MediaTitle = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  color: #161420;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 1600px) {
    font-size: 26px;
    line-height: 40px;
  }
`;
const MediaDesc = styled.div`
  p {
    margin: 15px 0;
  }
`;

const SectionFoundation = styled.div`
  text-align: center;
  margin-bottom: 50px;
  @media (min-width: 1200px) {
    margin-bottom: 100px;
  }
`;
const SectionFoundationTitle = styled.div`
  font-size: 24px;
  line-height: 40px;
  color: #161420;
  text-align: center;
  margin-bottom: 30px;
`;
const ListFoundation = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;
const ListItemFoundation = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`;
const FoundationCard = styled.div`
  position: relative;
  margin-bottom: 30px;
  text-align: center;
`;
const FoundationFigure = styled.div`
  padding: 6px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  .gatsby-image-wrapper {
    img {
      border-radius: 15px;
    }
  }
`;
const FoundationTitle = styled.div`
  margin-bottom: 5px;
  position: relative;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 38px;
  font-weight: 700;
  @media (min-width: 1200px) {
    font-size: 22px;
    line-height: 40px;
  }
`;
const FoundationDesc = styled.div`
  p {
    margin-bottom: 0;
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

const SitePreparationPage = ({ data, location }) => {
  const pageData = data.contentfulPageSitePreparation;
  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        location={location}
      />
      <Section
        bgColor="#F8FAFB"
        bgBefore="#002765"
        bgBeforeHeight="80%"
        bgBeforeWidth="100%"
      >
        <HeroBanner
          className="banner-hero"
          bgBefore="block"
          data-shadowtext="Site Preparation"
        >
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb>
                <Link to="/">Home</Link>
                <span>Site Preparation & Installation</span>
              </BreadCrumb>
              <HeroContent>
                <HeroLeft>
                  <HeroTitle>{pageData.heroTitle}</HeroTitle>
                </HeroLeft>
                <HeroRight>
                  <HeroCard>
                    <JoinForm location={location} />
                  </HeroCard>
                </HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>

      <Section
        ept="60px"
        epb="120px"
        xpt="60px"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#F8FAFB"
        className="section-siteprep"
      >
        <Container>
          <SectionTitle>{pageData.preparationSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1170px" ml="0" mb="30px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.preparationSectionDescription.childMarkdownRemark
                    .html,
              }}
            />
          </SectionDescription>
          <GridRow>
            {pageData.preparations.map((item, i) => (
              <Grid key={i}>
                <GridItem>
                  <MediaThumbnail className="media">
                    <MediaCheck className="media-check">
                      <span>
                        <CheckIcon />
                      </span>
                      <strong>{i + 1}</strong>
                    </MediaCheck>
                    <MediaBody>
                      <MediaFigure>
                        <GatsbyImage
                          image={item.image.gatsbyImageData}
                          alt={item.title}
                        />
                      </MediaFigure>
                      <MediaCaption>
                        <MediaTitle>{item.title}</MediaTitle>
                      </MediaCaption>
                    </MediaBody>
                  </MediaThumbnail>
                </GridItem>
                <GridItem>
                  <MediaDesc>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description.childMarkdownRemark.html,
                      }}
                    />
                  </MediaDesc>
                </GridItem>
              </Grid>
            ))}
          </GridRow>

          <SectionFoundation>
            <SectionFoundationTitle>
              {pageData.installationSectionTitle}
            </SectionFoundationTitle>
            <ListFoundation>
              {pageData.installations.map((item, i) => (
                <ListItemFoundation key={i}>
                  <FoundationCard>
                    <FoundationFigure>
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.title}
                      />
                    </FoundationFigure>
                    <FoundationTitle>{item.title}</FoundationTitle>
                    <FoundationDesc>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description.childMarkdownRemark.html,
                        }}
                      />
                    </FoundationDesc>
                  </FoundationCard>
                </ListItemFoundation>
              ))}
            </ListFoundation>
          </SectionFoundation>

          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </Container>
      </Section>
    </Layout>
  );
};

export default SitePreparationPage;

export const pageQuery = graphql`
  query SitePreparationPageQuery {
    contentfulPageSitePreparation {
      metaTitle
      metaDescription
      heroTitle
      preparationSectionTitle
      preparationSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      preparations {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
      installationSectionTitle
      installations {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
